import React from "react"
import { Container, Layout, SEO, Grid, TextRow, ImageRow, ExternalLink, Tag } from "../../components";

import example from '../../images/projects/astrolink-app.png';

const AstrolinkAPP = () => (
  <Layout hideProfile>
    <SEO title="Project: Astrolink Android App" />
    <Container>
      <h1>Astrolink Android App</h1>

      <Grid>
        <TextRow>
          <div>
            <Tag>Android</Tag>
            <Tag>Push Notification</Tag>
            <Tag>React Native</Tag>
          </div>

          <p>
            Astrolink is a social network about astrology which allows users to create their own birth chart free and see their horoscope daily.
            There are more than <strong>2 million</strong> users registered.
          </p>
          <p>
            I worked as one of the <strong>engineers</strong> with the purpose of to build a Android App for Astrolink users.
          </p>
          <p>
            This project was based on a WebView integrated with the PWA version.
            One of the main goals of this project it was to enable the <strong>push notification</strong> for the users,
            besides that to provide an amazing mobile experience, and it worked!
          </p>

          <p>
            The application was successful with more than <strong>100k downloads</strong> at <strong>Google Play</strong> Store and the rating of <strong>4.7</strong> by users.
          </p>

          <p>
            If you want to know more and download it,
            <ExternalLink href="https://play.google.com/store/apps/details?id=com.astrolink.webapp" target="_blank" rel="noopener noreferrer">click here!</ExternalLink>
          </p>
        </TextRow>

        <ImageRow>
          <img alt="Tangram design system example" src={example} />
        </ImageRow>

      </Grid>
    </Container>
  </Layout>
);

export default AstrolinkAPP;
